//TODO: remove/optimise initialState
export const initialState = {
  loaded: false,
  messageThreadId: null,
  isBulk: false,
  audience: "",
  recipients: [],
  toRecipients: [],
  subject: "",
  body: "",
  files: [],
  sending: false,
  submitted: false,
  inputErrors: null,
  serverMessage: null,
  confirmBulkMessageModal: false
};

const newMsgReducer = (state, action) => {
  switch (action.type) {
    case "validReset":
      return {
        ...state,
        loaded: true,
        validReset: true
      };
    case "invalidReset":
      return {
        ...state,
        validReset: false,
        loaded: true,
        serverMessage: { type: "error", text: action.payload }
      };
    case "handleClose":
      return initialState;
    case "handleInput":
      return {
        ...state,
        [action.field]: action.payload,
        inputErrors: { ...state.inputErrors, [action.field]: null },
        serverMessage: null
      };
    case "setToRecipients":
      return {
        ...state,
        toRecipients: action.payload,
        inputErrors: { ...state.inputErrors, toRecipients: null }
      };
    case "toggleIsBulk":
      return {
        ...state,
        isBulk: !state.isBulk,
        toRecipients: []
        // inputErrors: { ...state.inputErrors, audience: null }
      };
    case "setAudience":
      return {
        ...state,
        audience: action.payload,
        inputErrors: {
          ...state.inputErrors,
          toRecipients: ""
        }
      };
    case "addFiles":
      return {
        ...state,
        files: [...state.files, ...action.payload]
      };
    case "removeFile":
      return {
        ...state,
        files: state.files.filter((file, index) => index !== action.payload)
      };
    case "clearFiles":
      return {
        ...state,
        files: []
      };
    case "sending":
      return { ...state, sending: action.payload, submitted: true };
    case "handleInputErrors":
      return { ...state, inputErrors: action.payload };
    case "handleServerError":
      return {
        ...state,
        sending: false,
        serverMessage: { type: "error", text: action.payload }
      };
    case "handleServerSuccess":
      return {
        ...state,
        sending: false,
        serverMessage: { type: "success", text: action.payload }
      };
    case "toggleConfirmBulkMessageModal":
      return {
        ...state,
        confirmBulkMessageModal: !state.confirmBulkMessageModal,
        audience: action.payload
      };
    case "resetState":
      return initialState;
    default:
      return state;
  }
};

export default newMsgReducer;
