import React from "react";
import PropTypes from "prop-types";
import Paper from "@mui/material/Paper";

const HomePaper = props => (
  <Paper
    elevation={3}
    sx={{
      // do not use shorthand on overrides, props need to match exactly!
      width: { xs: "100%", md: "600px" },
      my: { xs: 2, md: 4 },
      padding: { xs: 1, sm: 2 },
      paddingTop: { md: 2 },
      paddingBottom: { md: 2 },
      paddingLeft: { md: 5 },
      paddingRight: { md: 5 },
      ...props.sx
    }}
    {...props}
  >
    {props.children}
  </Paper>
);

export default HomePaper;

HomePaper.propTypes = {
  children: PropTypes.node,
  sx: PropTypes.object
};
