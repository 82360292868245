import React from "react";
import PropTypes from "prop-types";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import DashboardPaper from "../../theme/styled/DashboardPaper";

import TransactionsTable from "./TransactionsTable";

const Transactions = props => {
  const {
    transactionsData,
    handleTransactionClick,
    handleTransactionDownload
  } = props;

  return (
    <DashboardPaper>
      <Box>
        <Typography
          variant="h6"
          sx={{
            mb: 2
          }}
        >
          Transaktioner
        </Typography>

        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <TransactionsTable
            transactions={transactionsData}
            handleTransactionClick={handleTransactionClick}
            handleTransactionDownload={handleTransactionDownload}
          />
        </LocalizationProvider>
      </Box>
    </DashboardPaper>
  );
};

Transactions.propTypes = {
  transactionsData: PropTypes.array,
  handleTransactionClick: PropTypes.func,
  handleTransactionDownload: PropTypes.func
};

export default Transactions;
