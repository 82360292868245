// import logo from "./logo.svg";
import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { CircularProgress } from "@mui/material";

import SectionHeaderPaper from "../../theme/styled/SectionHeaderPaper";
import SectionWrapperBox from "../../theme/styled/SectionWrapperBox";

import { Summary } from "..";
import Transactions from "../Transactions";

import useAccount from "./useAccount";

const AccountSection = () => {
  const { state, handleTransactionClick, handleTransactionDownload } =
    useAccount();

  return !state.loaded ? (
    <Box
      sx={{
        height: "100%",
        minHeight: "80vh",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      <CircularProgress />
    </Box>
  ) : (
    <Box>
      <SectionHeaderPaper>
        <Typography variant="h3" align="left">
          {state.accDetails.AccountName}
        </Typography>
      </SectionHeaderPaper>
      <SectionWrapperBox sx={{ flexFlow: "column", mx: 2.5 }}>
        <Summary
          summaryData={state.summaryData}
          accountDetails={state.accDetails}
        />
        <Transactions
          transactionsData={state.transactions}
          handleTransactionClick={handleTransactionClick}
          handleTransactionDownload={handleTransactionDownload}
          isAccount={true}
        />
      </SectionWrapperBox>
    </Box>
  );
};
export default AccountSection;
