import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import FairButton from "../../../theme/styled/FairButton";
import Divider from "@mui/material/Divider";

import useAxios from "../../../hooks/useAxios";
import useErrorHandler from "../../../hooks/useErrorHandler";
import { formatSafariFix } from "../../../helpers/functions";

const KYC_URL = process.env.REACT_APP_KYC_URL;

const generateCxtQueryString = IdentityNo => {
  const personalNumber = IdentityNo.replace("-", "");
  const contextObjJasonStr = JSON.stringify({
    customer: { id: personalNumber }
  });

  return btoa(contextObjJasonStr);
};

const BasicInfo = props => {
  const { user } = props;
  const { initAxios } = useAxios();
  const axiosRequestController = useRef(null);
  const errorHandler = useErrorHandler();
  const [kycCtxtQueryString, setKycCtxtQueryString] = useState(null);

  const profileCtxtQueryString = user.IdentityNo
    ? generateCxtQueryString(user.IdentityNo)
    : null;

  useEffect(() => {
    const getKycCheckString = async () => {
      try {
        const { axiosInstance, axiosController } = initAxios("private");
        axiosRequestController.current = axiosController;
        const { data } = await axiosInstance.get("user/kycCheck");
        return data;
      } catch (err) {
        errorHandler.serverError(err);
      }
    };

    if (!kycCtxtQueryString) {
      getKycCheckString().then(checkString => {
        const personalNumber = user.IdentityNo.replace("-", "");
        const contextObjJasonStr = JSON.stringify({
          customer: { id: personalNumber, check: checkString }
        });
        setKycCtxtQueryString(btoa(contextObjJasonStr));
      });
    }
  }, [kycCtxtQueryString, user.IdentityNo, initAxios, errorHandler]);

  return (
    <>
      <Box>
        <Typography variant="h6" align="left">
          Grunduppgifter
        </Typography>
      </Box>
      <Box>
        <Typography
          align="left"
          variant="h7"
          color="common.black"
          component="p"
          sx={{ mt: 2, mb: 1 }}
        >
          Namn
        </Typography>
        <Typography align="left" variant="body2" color="common.black">
          {user.FirstName} {user.LastName} {"("}
          {user.IdentityNo}
          {")"}
        </Typography>
      </Box>
      <Box>
        <Typography
          align="left"
          variant="h7"
          color="common.black"
          component="p"
          sx={{ mt: 2, mb: 1 }}
        >
          Adress
        </Typography>
        <Typography align="left" variant="body2" color="common.black">
          {user.AddressStreet}
        </Typography>
        <Typography align="left" variant="body2" color="common.black">
          {user.AddressZIP} {user.AddressCity}, {user.AddressCountry}
        </Typography>
      </Box>
      <Box>
        <Typography
          align="left"
          variant="h7"
          color="common.black"
          component="p"
          sx={{ mt: 2, mb: 1 }}
        >
          Föranmält konto
        </Typography>
        <Typography align="left" variant="body2" color="common.black">
          {user.BankAccountName
            ? user.BankAccountName
            : "Inget föranmält konto registrerat."}
        </Typography>
      </Box>
      <Box
        sx={{
          justifyContent: "flex-start",
          alignItems: "center",
          display: "flex",
          flexFlow: "row"
        }}
      >
        <Box
          sx={{
            justifyContent: "center",
            alignItems: "flex-start",
            display: "flex",
            flexFlow: "column"
          }}
        >
          <Typography
            align="left"
            variant="h7"
            color="common.black"
            component="p"
            sx={{ mt: 2, mb: 1 }}
          >
            Telefonnummer
          </Typography>
          <Typography align="left" variant="body2" color="common.black">
            {user.PhoneMobile
              ? user.PhoneMobile
              : user.Phone
              ? user.Phone
              : "-"}
          </Typography>
        </Box>
        <Box
          sx={{
            justifyContent: "center",
            alignItems: "flex-start",
            display: "flex",
            flexFlow: "column",
            ml: 5
          }}
        >
          <Typography
            align="left"
            variant="h7"
            color="common.black"
            component="p"
            sx={{ mt: 2, mb: 1 }}
          >
            Alternativt telefonnummer
          </Typography>
          <Typography align="left" variant="body2" color="common.black">
            {user.PhoneAlternative ? user.PhoneAlternative : "-"}
          </Typography>
        </Box>
      </Box>
      <Box>
        <Typography
          align="left"
          variant="h7"
          color="common.black"
          component="p"
          sx={{ mt: 2, mb: 1 }}
        >
          E-postadress
        </Typography>
        <Typography align="left" variant="body2" color="common.black">
          {user.Email ? user.Email : "-"}
        </Typography>
      </Box>
      <Box>
        {profileCtxtQueryString ? (
          <FairButton
            sx={{ mt: 3 }}
            href={`https://cob.kundinfo.fairinvestments.se/fair-kundinfo?context=${profileCtxtQueryString}`}
            rel="noopener noreferrer"
            target="_blank"
          >
            Ändra grunduppgifter
          </FairButton>
        ) : (
          <Typography
            align="left"
            variant="body2"
            color="common.black"
            sx={{ mt: 3, fontSize: "italic" }}
          >
            För att ändra dina grunduppgifter, var god ta kontakt med
            kundtjänst.
          </Typography>
        )}
      </Box>
      <Divider sx={{ my: 3 }} />
      <Box>
        <Typography variant="h7" align="left" color="common.black">
          Kundkännedomsuppgifter
        </Typography>
      </Box>
      <Box>
        <Typography
          align="left"
          variant="body2"
          color="common.black "
          sx={{ maxWidth: "640px", mt: 1 }}
        >
          Vi är enligt lag skyldiga att inhämta information om våra kunder och
          deras finansiella transaktioner samt se till så att de tjänster och
          produkter som vi erbjuder är lämpliga baserat på kundens situation och
          mål. Svaren på dessa frågor måste uppdateras med jämna mellanrum för
          att följa regelverk om bl.a. penningtvätt och finansiering av
          terrorism.
        </Typography>
        <Typography
          align="left"
          variant="body2"
          color="common.black"
          sx={{ mt: 1 }}
        >
          Kundkännedom senast uppdaterad:{" "}
          <b>
            {user.KycCompleted && user.KycCompleted !== "0000-00-00 00:00:00"
              ? formatSafariFix(user.KycCompleted, "yyyy-MM-dd")
              : "-"}
          </b>
        </Typography>
        <FairButton
          sx={{ my: 3 }}
          href={`${KYC_URL}?context=${kycCtxtQueryString}`}
          rel="noopener noreferrer"
          target="_blank"
        >
          Öppna formulär för kundkännedom
        </FairButton>
      </Box>
    </>
  );
};

export default BasicInfo;

BasicInfo.propTypes = {
  user: PropTypes.object
};
