import { useState, useEffect, useCallback, useRef } from "react";

import { findArrayKeyByPropertyValue } from "../../helpers/functions";

import useAxios from "../../hooks/useAxios";
import useErrorHandler from "../../hooks/useErrorHandler";
import { AuthConsumer } from "../../contexts/AuthProvider";

const useTransactions = () => {
  const { initAxios } = useAxios();
  const axiosGlobalController = useRef(null);
  const errorHandler = useErrorHandler();
  const [state, setState] = useState({ loaded: false, transactions: [] });
  const auth = AuthConsumer();

  const handleTransactionDownload = async (event, transaction) => {
    event.preventDefault;
    const { axiosInstance } = initAxios("private");

    transaction.OwnerName = auth.user.Name;
    transaction.OwnerId = auth.user.IdentityNo;

    // get document key in state.documents array
    const index = findArrayKeyByPropertyValue(
      state.transactions,
      "TransactionId",
      transaction.TransactionId
    );

    // set new document states
    state.transactions[index].Downloaded = false;
    state.transactions[index].Downloading = true;
    setState(prevState => ({
      ...prevState,
      transactions: state.transactions
    }));

    try {
      const response = await axiosInstance.post(
        "/documents/download/transaction",
        transaction,
        {
          responseType: "arraybuffer"
        }
      );

      const file = new Blob([response.data], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      const link = document.createElement("a");
      link.href = fileURL;
      link.download = "avräkningsnota.pdf";
      link.click();

      // update document states
      state.transactions[index].Downloading = false;
      state.transactions[index].Downloaded = true;
      setState(prevState => ({
        ...prevState,
        transactions: state.transactions
      }));
    } catch (err) {
      errorHandler.serverError(err);
    }
  };

  const handleTransactionClick = TransactionId => {
    const { transactions } = state;
    // find array index by object prop value
    const logIndex = transactions.findIndex(object => {
      return object["TransactionId"] === TransactionId;
    });

    let updatedData = [...transactions]; // copy prev state data array
    updatedData[logIndex]["toggled"] = !transactions[logIndex].toggled;
    setState(prevState => ({
      ...prevState,
      transactions: updatedData
    }));
  };

  const fetchTransactionsData = useCallback(async () => {
    const { axiosInstance, axiosController } = initAxios("private");
    axiosGlobalController.current = axiosController;

    try {
      // Account transactions data request
      const requestTransactions = await axiosInstance.get(
        `/account/all/transactions/owner`
      );

      setState(prevState => ({
        ...prevState,
        loaded: true,
        transactions: requestTransactions.data
      }));
    } catch (err) {
      errorHandler.serverError(err);
    }
  }, [errorHandler, initAxios]);

  useEffect(() => {
    if (!state.loaded) {
      fetchTransactionsData();
    }

    return () => axiosGlobalController.current?.abort();
  }, [fetchTransactionsData, state.loaded]);

  return {
    state,
    handleTransactionClick,
    handleTransactionDownload
  };
};

export default useTransactions;
