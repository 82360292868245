import { useState, useEffect, useCallback, useRef } from "react";
import { useLocation } from "react-router-dom";

import useAxios from "../../hooks/useAxios";
import useErrorHandler from "../../hooks/useErrorHandler";

import { filterLastThreads } from "../../helpers/functions";
import { AuthConsumer } from "../../contexts/AuthProvider";
import { DashboardConsumer } from "../../contexts/DashboardProvider";

const useMessages = () => {
  const { initAxios } = useAxios();
  const axiosGlobalController = useRef(null);
  const authCtxt = AuthConsumer();
  const dashboardCtxt = DashboardConsumer();
  const errorHandler = useErrorHandler();
  const location = useLocation();

  const { openMessage } = location?.state || {
    openMessage: false
  };

  const { user, elevatedUser } = authCtxt;

  const audiences = {
    all: "Alla",
    allWithExceptions:
      "Alla utan modellportfölj, enbart Harvest, Camp B eller Ädelmetaller",
    global: "Förvaltning: Global",
    flex: "Förvaltning: Flexibel",
    campB: "Camp B",
    harvest: "Harvest",
    preciousMetals: "Ädelmetaller"
  };

  const cannotSendMessages =
    user?.Role === "Customer" &&
    elevatedUser &&
    elevatedUser.Role !== "Customer" &&
    !elevatedUser.Role !== "Poa" &&
    !elevatedUser.IsPoaAgent;

  const [state, setState] = useState({
    loaded: false,
    cannotSendMessages,
    mailbox: "inbox",
    messages: { received: null, sent: null },
    ...(dashboardCtxt.state?.customers && {
      recipients: dashboardCtxt.state.customers
    }),
    openMessage,
    newMessage: false
  });

  const handleMailboxChange = (event, newValue) => {
    setState(prevState => ({
      ...prevState,
      mailbox: newValue
    }));
  };

  const fetchMessages = useCallback(() => {
    const { axiosInstance, axiosController } = initAxios("private");
    axiosGlobalController.current = axiosController;

    const requests = [];
    const requestMessagesReceived = axiosInstance.get(`/messages/received`);
    requests.push(requestMessagesReceived);

    const requestMessagesSent = axiosInstance.get(`/messages/sent`);
    requests.push(requestMessagesSent);

    Promise.all(requests)
      .then(responses => {
        setState(prevState => ({
          ...prevState,
          loaded: true,
          messages: {
            received: filterLastThreads(responses[0].data),
            sent: filterLastThreads(responses[1].data)
          }
        }));
        dashboardCtxt.setState(prevState => ({
          ...prevState,
          messages: filterLastThreads(responses[0].data)
        }));
      })
      .catch(function (err) {
        errorHandler.serverError(err);
      });
  }, [dashboardCtxt, errorHandler, initAxios]);

  const handleOpenMessage = useCallback(
    message => async e => {
      e.preventDefault();

      setState(prevState => ({
        ...prevState,
        openMessage: message,
        newMessage: false
      }));
    },
    []
  );

  const handleCloseMessage = useCallback(e => {
    e.preventDefault();
    setState(prevState => ({
      ...prevState,
      openMessage: false,
      newMessage: false
    }));
  }, []);

  const handleNewMessage = useCallback(e => {
    e.preventDefault();
    setState(prevState => ({ ...prevState, newMessage: true }));
  }, []);

  useEffect(() => {
    if (!state.loaded) {
      fetchMessages();
    }
    return () => axiosGlobalController.current?.abort();
  }, [fetchMessages, state.loaded]);

  return {
    state,
    audiences,
    handleMailboxChange,
    fetchMessages,
    handleOpenMessage,
    handleCloseMessage,
    handleNewMessage
  };
};

export default useMessages;
